@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe{
  display: none;
}
html {
  overflow: hidden;
  height: 100%;
  overscroll-behavior: none;
}

body {
  height: 100%;
  overflow: auto;
  background: transparent;
  overscroll-behavior-y: none;
}

.wmde-markdown{
  min-height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  background-color: unset !important;
}

.wmde-markdown pre > code{
    overflow: unset !important;
    display: block;
    white-space: pre-wrap !important;
}
.version{
  align-items: flex-end;
    bottom: 0.5em;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    opacity: .25;
    pointer-events: none;
    position: fixed;
    right: 0.5em;
    width: 100vw;
    color:#C4C4C4;
}

.css-grid{
   display: flex;           
  align-items: center;     
  justify-content: center;  
}
.hide-native-scrollbar {
  scrollbar-width: none;
  /* Firefox 64 */
  -ms-overflow-style: none;
  /* Internet Explorer 11 */
}

.hide-native-scrollbar::-webkit-scrollbar {
  /** WebKit */
  display: none;
}

body {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden
}

.standard-button {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(0.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.standard-button:hover,
.standard-button:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.standard-button:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.standard-button:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.defaultPage-bg {

  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/DefaultPageBackground.1eec243d.jpg);
  background-position: center;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  position: 'absolute';
  height: 40;
  left: 0;
  top: WINDOW_HEIGHT - 40;
  width: WINDOW_WIDTH;
}

.backdrop {
  background: #FFFFFF;
  border-radius: 1em;
  box-shadow: 16px, 16px, 8px, red;
}

.layout {
  --linear-layout-item-gap: 5px;
}

.sub-layout>* {
  --linear-layout-item-gap: 20px;
}

.input-text {
  color: black !important;
  border-color: yellowgreen !important;
}

.input-text::before {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 1px solid grey !important;

}

.input-text::after {
  color: black !important;
  border-color: yellowgreen !important;
  border-bottom: 2px solid #114B7A !important;

}

.input-text-container {
  color: #F5F6FA !important;
  border-color: yellowgreen !important;
  background-color: #F5F6FA;
  border-radius: 10px;
}

.input-text-label {
  color: #C4C4C4 !important;
  font-family: "Lato";
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.roll-out {
  @-webkit-keyframes rollout {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    100% {
      -webkit-transform: translateX(25px);
              transform: translateX(25px);
    }
  }
  @keyframes rollout {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    100% {
      -webkit-transform: translateX(25px);
              transform: translateX(25px);
    }
  }

  -webkit-animation: rollout 0.5s;

          animation: rollout 0.5s;
}

.roll-out-mobile {
  @-webkit-keyframes rolloutmobile {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    100% {
      -webkit-transform: translateX(15px);
              transform: translateX(15px);
    }
  }
  @keyframes rolloutmobile {
    0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }

    100% {
      -webkit-transform: translateX(15px);
              transform: translateX(15px);
    }
  }

  -webkit-animation: rolloutmobile 0.5s;

          animation: rolloutmobile 0.5s;
}

.roll-in {
  @-webkit-keyframes rollin {
    0% {
      -webkit-transform: translateX(25px);
              transform: translateX(25px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  @keyframes rollin {
    0% {
      -webkit-transform: translateX(25px);
              transform: translateX(25px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  -webkit-animation: rollin 0.5s;

          animation: rollin 0.5s;
}

.roll-in-mobile {
  @-webkit-keyframes rollinmobile {
    0% {
      -webkit-transform: translateX(15px);
              transform: translateX(15px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  @keyframes rollinmobile {
    0% {
      -webkit-transform: translateX(15px);
              transform: translateX(15px);
    }

    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }

  -webkit-animation: rollinmobile 0.5s;

          animation: rollinmobile 0.5s;
}

.fade-in-element-a {
  @-webkit-keyframes fade-in-a {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-a {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fade-in-a 0.5s;

          animation: fade-in-a 0.5s;
}

.fade-in-element-b {
  @-webkit-keyframes fade-in-b {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-b {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fade-in-b 0.5s;

          animation: fade-in-b 0.5s;
}

.fade-in-element-c {
  @-webkit-keyframes fade-in-c {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-c {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fade-in-c 0.5s;

          animation: fade-in-c 0.5s;
}

.fade-in-element-d {
  @-webkit-keyframes fade-in-d {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-d {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fade-in-d 0.5s;

          animation: fade-in-d 0.5s;
}

.fade-in-element-e {
  @-webkit-keyframes fade-in-e {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-e {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  -webkit-animation: fade-in-e 0.5s;

          animation: fade-in-e 0.5s;
}

.ripple-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.ripple-emitter {
  position: relative;
  margin: 0 auto;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
}

.ripple-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-image: linear-gradient(to right, white 0%, #fee140 100%);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

.minimize-dashboard {
  max-width: 1000px;
  -webkit-animation: minimizeDashboard 20.5s;
          animation: minimizeDashboard 20.5s
}

.maximize-dashboard {
  animation: minimizeDashboard 0.5s reverse
}

.dashboard-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}



#custom-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F520;
}

#custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #FFDACC;
}

.voucher-bar-offer {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.ripple-wave {
  -webkit-animation: rippleWave 4s ease-in infinite;
          animation: rippleWave 4s ease-in infinite;
}


.ripple-float {
  -webkit-animation: rippleFloat 4s ease-in infinite;
          animation: rippleFloat 4s ease-in infinite;
}

@-webkit-keyframes rippleWaveBG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
    -webkit-filter: blur(0.25em);
            filter: blur(0.25em);
  }

  50% {
    opacity: 0.125;
    -webkit-filter: blur(1em);
            filter: blur(1em)
  }

  100% {
    -webkit-transform: scale(1) translateY(-10vh);
            transform: scale(1) translateY(-10vh);
    opacity: 0;
    -webkit-filter: blur(0.5em);
            filter: blur(0.5em);
  }
}

@keyframes rippleWaveBG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
    -webkit-filter: blur(0.25em);
            filter: blur(0.25em);
  }

  50% {
    opacity: 0.125;
    -webkit-filter: blur(1em);
            filter: blur(1em)
  }

  100% {
    -webkit-transform: scale(1) translateY(-10vh);
            transform: scale(1) translateY(-10vh);
    opacity: 0;
    -webkit-filter: blur(0.5em);
            filter: blur(0.5em);
  }
}

@-webkit-keyframes rippleWaveMG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
    -webkit-filter: blur(0.05em);
            filter: blur(0.05em);
  }

  50% {
    opacity: 0.125;
    -webkit-filter: blur(0.2em);
            filter: blur(0.2em)
  }

  100% {
    -webkit-transform: scale(1) translateY(-40vh);
            transform: scale(1) translateY(-40vh);
    opacity: 0;
    -webkit-filter: blur(0.1em);
            filter: blur(0.1em);
  }
}

@keyframes rippleWaveMG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
    -webkit-filter: blur(0.05em);
            filter: blur(0.05em);
  }

  50% {
    opacity: 0.125;
    -webkit-filter: blur(0.2em);
            filter: blur(0.2em)
  }

  100% {
    -webkit-transform: scale(1) translateY(-40vh);
            transform: scale(1) translateY(-40vh);
    opacity: 0;
    -webkit-filter: blur(0.1em);
            filter: blur(0.1em);
  }
}

@-webkit-keyframes rippleWaveFG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
  }

  50% {
    opacity: 0.125;
  }

  100% {
    -webkit-transform: scale(1) translateY(-80vh);
            transform: scale(1) translateY(-80vh);
    opacity: 0;
  }
}

@keyframes rippleWaveFG {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.075) translateY(20vh);
            transform: scale(0.075) translateY(20vh);
  }

  50% {
    opacity: 0.125;
  }

  100% {
    -webkit-transform: scale(1) translateY(-80vh);
            transform: scale(1) translateY(-80vh);
    opacity: 0;
  }
}

@-webkit-keyframes rippleFloat {
  0% {
    -webkit-transform: translateY(-20vh);
            transform: translateY(-20vh);
  }

  100% {
    -webkit-transform: translateY(-60vh);
            transform: translateY(-60vh);
  }
}

@keyframes rippleFloat {
  0% {
    -webkit-transform: translateY(-20vh);
            transform: translateY(-20vh);
  }

  100% {
    -webkit-transform: translateY(-60vh);
            transform: translateY(-60vh);
  }
}

@-webkit-keyframes minimizeDashboard {
  0% {
    width: 100vw;
  }

  100% {
    width: 900px;
  }
}

@keyframes minimizeDashboard {
  0% {
    width: 100vw;
  }

  100% {
    width: 900px;
  }
}

@-webkit-keyframes maximizeDashboard {
  0% {
    width: 900px;
  }

  100% {
    width: 100vw;
  }
}

@keyframes maximizeDashboard {
  0% {
    width: 900px;
  }

  100% {
    width: 100vw;
  }
}

@-webkit-keyframes minimize-info-text {
  0% {
    font-size: 28px;
  }

  100% {
    font-size: 20px;
  }
}

@keyframes minimize-info-text {
  0% {
    font-size: 28px;
  }

  100% {
    font-size: 20px;
  }
}


@-webkit-keyframes maximize-info-text {
  0% {
    font-size: 20px;
  }

  100% {
    font-size: 28px;
  }
}


@keyframes maximize-info-text {
  0% {
    font-size: 20px;
  }

  100% {
    font-size: 28px;
  }
}

.feed-light > li {
  /* filter: invert(1); */
  color: black;
}

.feed-dark > li {
  /* filter: invert(1); */
  color: white;
}
.more-btn{
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: -webkit-min-content;
    width: min-content;
    padding: 8px;
    margin-top: 20px;
    cursor: pointer;
}

.more-btn:hover{
    background: rgba(255, 255, 255, 0.35);
}
